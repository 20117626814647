const NextI18Next = require("next-i18next").default;

module.exports = new NextI18Next({
  defaultLanguage: "ja",
  otherLanguages: ["en"],
  // localePath: typeof window === 'undefined' ? 'public/static/locales' : 'static/locales',
  localeSubpaths: {
    en: "en",
  },
  shallowRender: true,
});
